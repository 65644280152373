import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import {Slide1Phone, TextWrapper, Wrapper, Next} from './styled';
import Fade from 'react-reveal/Fade';

export const Slide2 = () => {
  return (
    <Wrapper>
      <div>
        <StaticImage width={540} src="../../../images/tvilu/tvilu.png" />
      </div>
      <div>
        <TextWrapper>
          <div>
            <StaticImage layout={'fixed'} src="../../../images/tvilu/logo.png" />
          </div>
          Transforming salons and beauty parlours 
          <br/>into <span>digital services</span>
          <p>
            Our strategies are proven with best results from our client stories.
            Check out our latest projects and deliveries.
          </p>
        </TextWrapper>
      </div>
    </Wrapper>
  );
};

export default Slide2;
