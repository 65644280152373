import React, { useContext } from 'react';
import Image from '../../images/created.svg';
import { Slide1Phone, TextWrapper, LetsTalk } from './Slide1/styled';
import { Slide1Wrapper, Next, MessageUsers, ChatMessageUser } from './styled';
import SearchIcon from '../../images/search-icon.svg';
import LetsTalkIcon from '../../images/svgs/lets-talk.svg';
import Fade from 'react-reveal/Fade';
import { ContactContext } from '../../internal/context/ContactContext';

export const Slide1 = () => {
  const { setShowModal } = useContext(ContactContext);

  return (
    <Slide1Wrapper>
      <div>
        <TextWrapper>
          <span>Scalable</span> products<br />
          with teams that<br />
          <span>deliver!</span>
          <div className='mt-4'>
            Our strategies are proven with best results from our client stories.
            Check out our latest projects and deliveries.
          </div>
        </TextWrapper>
        <LetsTalk>
          <span>Let's Talk</span>
          <div onClick={() => setShowModal(true)}>
            <LetsTalkIcon />
          </div>
        </LetsTalk>
      </div>

      <div>
        <ChatMessageUser>
          <Fade left>
            <div><img src="/images/home/chat-messages.png" /></div>
          </Fade>
        </ChatMessageUser>
        <MessageUsers>
          <Fade delay={1000} right>
            <div><img src="/images/home/background.png" /></div>
          </Fade>

        </MessageUsers>
        <Slide1Phone>
          <div>Hello there, </div>
          <div>Welcome to Zegates! ^_^</div>
          <Fade bottom delay={6000}>
            <div>
              Search more
              <SearchIcon />
            </div>
          </Fade>
          <Fade bottom delay={7500}>
            <div>
              <div />
              <div />
              <div />
            </div>
          </Fade>
          <Fade bottom delay={9000}>
            <div>
              <div />
              <p>Your awesome content</p>
            </div>
          </Fade>
          <Fade bottom delay={10500}>
            <div>Ready in no time! 🎉</div>
          </Fade>
        </Slide1Phone>

      </div>
    </Slide1Wrapper>
  );
};
