import styled, {css, keyframes} from 'styled-components';

export const Background = styled.div`
  background: ${({theme}) => theme.palatte.background.light};
  overflow: hidden;
  padding-top: 100px;
  display: block;
`;

export const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 60vh;

  & > div {
    min-width: 920px;
  }
  @media (max-width: 600px) {
    & > div {
      min-width: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const HomeTitle = styled.div`
  border-top: 1px solid ${({theme}) => theme.palatte.text.default};
  border-bottom: 1px solid ${({theme}) => theme.palatte.text.default};
  max-width: 1000px;
  margin: auto;
  line-height: 1.5;

  & > h1 {
    font-family: 'Thicccboi', sans-serif;
    padding: 1rem 0rem;
    font-size: 3rem;
    max-width: 700px;
    font-weight: 700;
    margin: 2rem 0px;
  }

  @media (max-width: 992px) {
    padding: 0px 1em;
  }

`

const animation = keyframes`
    from {width: 0;}
    to {width: 230px;}
`;

export const StepCount = styled.div`
  display: flex;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 200px;
  justify-content: space-between;
  
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    & > div {
      margin-bottom: 40px;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    & > div > div {
      text-align: center;
    }
  }

  & > div {
    max-width: 260px;
  }

  & > div > div:first-child {
    margin-bottom: 50px;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    font-size: 1.8em;
    background: ${({theme}) => theme.palatte.text.contrast};
    border: 2px solid ${({theme}) => theme.palatte.text.default};
  }

  & > div > div:last-child {
    font-size: 1em;
    line-height: 1.8;
  }
`

export const StepWrapper = styled.div`
  font-family: 'Thicccboi', sans-serif;
  height: 80px;
  width: 0px;
  color: ${({theme}) => theme.palatte.text.default};
  overflow: hidden;
  display: flex;
  font-size: 1.7em;
  font-weight: 500;
  justify-content: center;
  margin-left: ${props => props.step * 230}px;
  align-items: center;
  background: ${({theme}) => theme.palatte.colors.lightgreen};

  ${props =>
    props.isFocused
      ? css`
          animation: ${animation} 0.6s;
        `
      : css`animation: none`};
  animation-delay: ${props => (props.step + 1) * 0.6}s;
  animation-fill-mode: forwards;

  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;
