import React, {useState, useEffect} from 'react';
import Fade from 'react-reveal/Fade';
import {Prev, Wrapper, Next} from './styled';
import NextSvg from '../../images/next.svg';
import {Slide1} from './Slide1';
import Slide2 from './Slide2';

const slideCount = 2;
let time = null;

const Slider = () => {
  const [currentSlide, setSlide] = useState(0);

  const animate = () => {
    setTimeout(() => {
      press();
      // animate();
    }, currentSlide === 0 ? 13000 : 3000);
  };

  useEffect(() => {
    clearTimeout(time);
    // animate();
  }, []);

  const press = (next = true) => {
    if (next) {
      setSlide(currentSlide + 1 >= slideCount ? 0 : currentSlide + 1);
    } else {
      setSlide(currentSlide - 1 < 0 ? slideCount - 1 : currentSlide - 1);
    }
  };

  return (
    <Wrapper>
      <Fade spy={currentSlide} delay={50}>
        <Wrapper>{getSlide(currentSlide)}</Wrapper>
      </Fade>
      {/* <Prev onClick={() => press(false)}>
        <NextSvg />
      </Prev>
      <Next onClick={press}>
        <NextSvg />
      </Next> */}
    </Wrapper>
  );
};

const getSlide = (index) => {
  switch (index) {
    case 0: {
      return <Slide1 />;
    }
    case 1: {
      return <Slide2 />;
    }
  }
  return <Slide1 />;
};

export default Slider;
