import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 90vh;
  min-height: 900px;
  background: #fff;
  display: block;


  @media (max-width: 768px) {
    height: 100%;

  }
`;

export const FadeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  display: block;
`;

const animation = keyframes`
    from {transform: translate3d(320px, 300px, 0px);}
    to {transform: translate3d(0px, 0px, 0px);}
`;

const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const Slide1Wrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #E5FBFC;
  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: ${gradient} 15s ease infinite;

  & > div:last-child {
    width: 50%;
    position: relative;
    display: flex;
    justify-content: center;
  }

  & > span {
    height: 50vh;
    width: 1px;
    background: #000;
    
  }

  @media (max-width: 600px) {
    & > div:nth-child(2) {
      transform: scale(0.8);
    }
  }

  @media (max-width: 992px) {
    padding: 8px;
    & > div:first-child {
      width: 330px;
    }

    & > div:nth-child(2) {
      width: 50%;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px;

    & > span {
      width: 1px;
      background: #000;
    }

    & > div:first-child {
      width: calc(100% - 16px);
      padding: 0px;
      padding-bottom: 40px;
    }
    
    & > div:nth-child(2) {
      width: 100%;
      margin-bottom: 140px;
    }
  
  }

`;

export const Slide1SVG = styled.div`
  & > div {
    margin: auto;
    //transform:
    //rotateX(59deg)
    //rotateZ(27deg);
    transform-style: preserve-3d;
    width: 400px;
  }

  & > div > svg > g > #Group_2193 {
    transform: translate3d(-464px, 300px, 0px);
  }
  & > div > svg > g > #Rectangle_365 {
    box-shadow: 1px 1px 0 1px #f9f9fb, -1px 0 28px 0 rgba(34, 33, 81, 0.01),
      28px 28px 28px 0 rgba(34, 33, 81, 0.25);
    animation: ${animation};
    animation-delay: 1s;
    transition: 0.4s ease-in-out transform, 0.4s ease-in-out box-shadow;
  }
`;

export const Next = styled.div`
  position: absolute;
  right: 20px;
  top: 50vh;

  &:hover {
    cursor: pointer;
  }
`;

export const Prev = styled.div`
  height: 40px;
  position: absolute;
  transform: rotate(180deg);
  left: 20px;
  top: 50vh;

  &:hover {
    cursor: pointer;
  }
`;

export const MessageUsers = styled.div`
    border-radius: 24px;
    padding: 16px;
    position: absolute;
    z-index: 100;
`
export const ChatMessageUser = styled.div`
  position: absolute;
  z-index: 0;
  margin-right: 64px;
`
