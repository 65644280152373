import React, { useRef } from 'react';
import { ProjectWrapper, ServiceProjWrapper } from './styled';
import Fade from 'react-reveal/Fade';
import { StaticImage } from "gatsby-plugin-image"

const ServiceProjects = () => {
  const ref = useRef();

  const scroll = (scrollOffset) => {
    if (window?.innerWidth < 600) {
      ref.current.scrollLeft += scrollOffset > 0 ? window.innerWidth: -window?.innerWidth;
    } else {
      ref.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <ServiceProjWrapper>
      <div className='projects'>
        <div ref={ref}>
          <ProjectWrapper color='lightgreen' href="/projects/netlise">
            <div>
              <img src="/images/projects/netlise-pos.png" />
            </div>
          </ProjectWrapper>
          <ProjectWrapper color='yellow' href="/projects/wirl">
            <div>
              <img style={{width: '90%'}} src="/images/projects/wirl-video-shopping.png" />
            </div>
          </ProjectWrapper>

          <ProjectWrapper color='lightblue'>
            <div>
              <img style={{width: '90%'}} src="/images/projects/inspectafy-project.png" />
            </div>
          </ProjectWrapper>
          <ProjectWrapper color='lilly' href="/projects/wergo">
            <div>
              <img src="/images/projects/wergo/wergo-preview.png" />
            </div>
          </ProjectWrapper>
        </div>
      </div>
      <div className='controls'>
        <div onClick={() => scroll(-400)}>
          <img src="/images/icons/left-icon.png" />
        </div>
        <div onClick={() => scroll(400)}>
          <img src="/images/icons/right-icon.png" />
        </div>
      </div>
    </ServiceProjWrapper>
  );
};

export default ServiceProjects;
