import styled, {keyframes} from 'styled-components';

const typing = keyframes`
  from { width: 0; }
  to { width: 130px; }
`;

const typing2 = keyframes`
  from { width: 0; }
  to { width: 260px; }
`;

const caret = keyframes`
  from, to { border-color: orange; }
  50% { border-color: transparent; }
`;

export const Slide1Phone = styled.div`
  border: 1px solid #000;
  background: ${({theme}) => theme.palatte.text.default};
  margin: auto;
  height: 540px;
  border-radius: 24px;
  color: #fff;
  width: 300px;
  position: relative;
  margin-top: 80px;
  z-index: 0;

  & > div {
    margin: 30px 20px;
  }

  @media (max-width: 600px) {
    width: auto;
    // margin: 16px;
    max-width: 320px;
  }

  & > div:nth-child(1) { 
    overflow: hidden;
    width: 130px;
    border-right: 0.15em solid transparent;
    white-space: nowrap;
    letter-spacing: 0.15em;

    animation: ${typing} 1.5s steps(20, end), ${caret} 0.35s step-end 3;
  }

  & > div:nth-child(2) { 
    border-right: 0.15em solid transparent;
    white-space: nowrap;
    letter-spacing: 0.15em;
    overflow: hidden;
    width: 0px;

    animation: ${typing2} 3.5s steps(40, end), ${caret} 0.75s infinite;
    animation-delay: 1.8s;
    animation-fill-mode: forwards;
  }

  & > div:nth-child(3) { 
    border: 1px solid ${({theme}) => theme.palatte.text.contrast};
    letter-spacing: 0.10em;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 8px 16px;

    overflow: hidden;
  }

  & > div:nth-child(4) { 
    display: flex;
    overflow: hidden;
    justify-content: space-between;
  }

  & > div:nth-child(4) > div { 
    height: 76px;
    width: 76px;
    display: block;
    border-radius: 8px;
    border: 1px solid ${({theme}) => theme.palatte.text.contrast};
  }

  & > div:nth-child(5) { 
    border: 1px solid ${({theme}) => theme.palatte.text.contrast};
    border-radius: 8px;
  }
  
  & > div:nth-child(5) > div { 
    height: 80px;
    background: #fff;
    margin: 16px;
    border-radius: 8px;
  }

  & > div:nth-child(5) > p { 
    letter-spacing: 0.10em;
    text-align: center;
    margin: 16px;
  }

  & > div:nth-child(6) { 
    text-align: center;
    letter-spacing: 0.10em;
  }
`;

export const TextWrapper = styled.div`
  & > span {
    font-weight: 600;
    // color: ${({theme}) => theme.palatte.text.highlight};
  }

  font-size: 4rem;
  font-weight: 100;
  margin: 40px;
  line-height: 4.5rem;
  // border: 1px solid ${({theme}) => theme.palatte.text.default};
  padding: 3rem 4rem;
  border-top-right-radius: 6rem;
  border-bottom-left-radius: 6rem;
  font-family: Thicccboi;

  & > div {
    max-width: 460px;
    margin-top: 32px;
    margin-right: 40px;
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 400;
    font-family: Open sans;
  }

  @media (max-width: 992px) {
    margin: 24px 16px;
    padding: 2rem 0rem;
    border-top-right-radius: 3rem;
    border-bottom-left-radius: 3rem;
    font-size: 3rem;
    line-height: 3.6rem;
  }

  // @media (max-width: 600px) {
  //   margin: 40px 8px;
  //   padding: 2rem 1.2rem;
  //   border-top-right-radius: 3rem;
  //   border-bottom-left-radius: 3rem;
  //   font-size: 2.2rem;
  //   line-height: 3rem;
  // }

`;

export const LetsTalk = styled.div`
  margin-right: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 4rem;

  span {
    font-size: 18px;
  }

  & > div {
    height: 70px;
    width: 70px;
    border-radius: 40px;
    background-color: ${({theme}) => theme.palatte.text.default};
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in;
  }

  & > div:hover {
    cursor: pointer;
    background-color: ${({theme}) => theme.palatte.background.highlight};
  }

  @media (max-width: 768px) {
  }
`;
