import styled from 'styled-components';

export const Background = styled.div`
  background: ${({theme}) => theme.palatte.background.light};
  overflow: hidden;
  display: block;
  border-top-left-radius: 6rem;
  border-top-right-radius: 6rem;
  margin-top: -7rem;
`;

export const Wrapper = styled.div`
  max-width: 1000px;
  margin: auto;
  padding: 100px;
  padding-top: 80px;

  @media (max-width: 992px) {
    padding: 24px;
    padding-top: 40px;
  }

  @media (max-width: 600px) {
    padding: 16px;
    padding-top: 40px;
    line-height: 56px;
  }
`;

export const PhoneSpan = styled.div`
  border: 2px solid ${({theme}) => theme.palatte.text.default};
  height: 600px;
  border-radius: 24px;
  width: 350px;

  @media (max-width: 600px) {
    border-width: 0px;
  }
`;

export const Text1 = styled.div`
  font-size: 3.2em;
  font-weight: 700;
  margin-left: -135px;
  margin-top: 40px;
  word-spacing: 20px;

  @media (max-width: 600px) {
    margin-top: 60px;
    margin-left: auto;
  }
`;

export const Text2 = styled.div`
  font-size: 3.2em;
  margin-left: 15px;
  font-weight: 700;

  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;

export const Text3 = styled.div`
  margin-top: 80px;
  font-size: 1.125em;
  line-height: 1.5rem;
  max-width: 400px;
  font-weight: 400;
  margin-left: 15px;
`;

export const Image = styled.div`
  bottom: -100px;
  margin-left: -80px;
  position: absolute;

  @media (max-width: 600px) {
    bottom: 0px;
    top: 0px;
    margin-left: 0px;
  }
`;

export const ServiceInd = styled.div`
  height: 90vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0fbfc;
  margin: auto;
  width: 100%;
  display: flex;

  & > div > div {
    display: flex;
    padding: 40px;
  }
  @media (max-width: 600px) {
    height: auto;

    & > div > div {
      flex-direction: column;
    }
  }
`;

export const SP1 = styled.div`
  font-size: 6em;
  font-weight: 200;
  text-align: center;
  margin-top: 10px;

  & > span {
    font-weight: 800;
    font-style: italic;
  }

  @media (max-width: 600px) {
    font-size: 3.5em;
    line-height: 1.4em;
  }
`;

export const Clients = styled.div`
  display: flex;
  margin-top: 100px;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;

  @media (max-width: 600px) {
    flex-wrap: wrap;

    // & > div {
    //   transform: scale(0.5);
    //   width: auto !important;
    //   height; auto !important;
    // }
  }
`;

export const SP2 = styled.div`
  margin: 120px auto 60px auto;
  font-size: 1.5em;
  text-align: center;
  max-width: 580px;

  @media (max-width: 600px) {
    margin: 60px auto 30px auto;
    font-size: 1.3em;
    line-height: 1.6em;
  }
`;

export const SP3 = styled.div`
  margin: 20px auto;
  text-align: center;
  max-width: 580px;
  font-size: 1.125em;
  font-weight: 300;
`;

export const ServiceSelWrapper = styled.div`
  height: 1000px;
  display: flex;
  max-width: 1200px;
  justify-content: center;
  align-items: center;
  margin: auto;

  @media (max-width: 732px) {
    flex-direction: column;
    height: auto;
    margin-top: 32px;
  }
`;

export const BoxWrapper = styled.div`
  width: 40%;

  @media (max-width: 732px) {
    width: 100%;
    margin: 40px 0px;
  }
`;

export const DisplayWrapper = styled.div`
  width: 60%;

  @media (max-width: 732px) {
    width: 100%;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  max-width: 400px;
  background: ${({selected, theme}) =>
    selected ? theme.palatte.text.default : '#fff'};
  padding: 0px 40px;
  color: ${({selected, theme}) =>
    selected ? '#fff' : theme.palatte.text.default};
  border: 1px solid ${({theme}) => theme.palatte.text.default};
  margin-top: -1px;

  &:hover {
    cursor: pointer;
    color: ${({theme}) => theme.palatte.text.default};
    background: ${({theme}) => theme.palatte.text.contrast};
  }

  & > h3 {
    font-size: 1.5em;
    font-weight: 400;
    margin: 0px 0px 16px 0px;
  }

  @media (max-width: 1024px) {
    padding: 0px 16px;
    margin: auto;
  }

  @media (max-width: 600px) {
    padding: 0px 16px;
    width: calc(100% - 56px);
    margin: 0px auto;

    & > h3 {
      font-size: 1.3em;
      font-weight: 400;
      margin: 0px 0px 12px 0px;
    }
  }
`;

export const ServiceProjWrapper = styled.div`
  margin: 80px;

  @media (max-width: 600px) {
    margin: 40px 0px;

    .projects {
      overflow: hidden;
      height: 332px;

      & > div {
        height: 100%;
        display: flex;
        padding-top: 16px;
        scroll-behavior: smooth;
        width: 100%;
        overflow-x: scroll;
        padding-bottom: 24px;
        box-sizing: content-box;
      }
    }
  }

  .projects {
    overflow: hidden;
    height: 332px;

    & > div {
      height: 100%;
      display: flex;
      padding-top: 16px;
      scroll-behavior: smooth;
      width: 100%;
      overflow-x: scroll;
      padding-bottom: 24px;
      box-sizing: content-box;
    }
  }
  .controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    & > div {
      background: none;
      border: none;
      cursor: pointer;
      padding-left: 16px;
    }
  }

  @media (max-width: 600px) {
    margin: 40px 0px;
  }
`;

export const ProjectWrapper = styled.a`
  height: 300px;
  width: 400px;
  display: block;
  border-radius: 24px;
  background: ${({theme}) => theme.palatte.colors.blue};
  margin-left: 32px;
  position: relative;
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
  }

  & > div {
    position: absolute;
    border-radius: 24px;
    height: 300px;
    width: 400px;
    background: ${({theme, color}) => theme.palatte.colors[color]};
    bottom: 16px;
    left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 16px;

    & > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  @media (max-width: 600px) {
    margin-left: 0px;
    margin-right: 32px;
    height: 300px;
    width: calc(100vw - 32px);

    & > div {
      position: absolute;
      border-radius: 24px;
      width: calc(100vw - 32px);
    }
  }
`;

export const ServicePerf = styled.div`
  height: 90vh;
  position: relative;
  margin: auto;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    // padding: 0px 1em;
  }

  & > .ui-section {
    max-width: 1000px;
    width: 100%;
    margin: auto;
    display: flex;

    & > div {
      width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 1em;
      align-items: center;
      overflow: hidden;

      & > h1 {
        font-family: Thicccboi, sans-serif;
        line-height: 1.4;
      }

      & > p {
        line-height: 1.8;
      }
    }

    & > div:last-child > img {
      max-width: 480px;
      max-height: 406px;
    }
    & > div:last-child {
    }
  }

  @media (max-width: 600px) {
    height: auto;

    & > .ui-section {
      flex-direction: column;
    }
    & > .ui-section > div {
      flex-direction: column;
      width: calc(100% - 2em);
    }
  }

  & > .ui-section {
    max-width: 1000px;
    width: 100%;
    margin: auto;
    display: flex;
  }

  & > .ui-desc {
    max-width: 1000px;
    width: 100%;
    gap: 1em;
    margin: auto;
    margin-top: 0px;
    display: flex;
    justify-content: space-between;

    & > img {
      object-fit: contain;
    }
    @media (max-width: 992px) {
      width: calc(100% - 2em);
      padding: 1em;
      flex-wrap: wrap;
    }
  }
`;
