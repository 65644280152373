import styled, {keyframes} from 'styled-components';

const typing = keyframes`
  from { width: 0; }
  to { width: 130px; }
`;

const typing2 = keyframes`
  from { width: 0; }
  to { width: 260px; }
`;

const caret = keyframes`
  from, to { border-color: orange; }
  50% { border-color: transparent; }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(216, 152, 125, .15);
  display: flex;

  & > div:first-child {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  & > div:last-child {
    width: 40%;
  }

  & > span {
    height: 50vh;
    width: 1px;
    background: #000;
  }
`;

export const TextWrapper = styled.div`
  & > span {
    font-weight: 600;
    color: ${({theme}) => theme.palatte.text.highlight};
  }

  font-size: 3em;
  font-weight: 100;
  margin: 40px;
  line-height: 1.3;

  & > div {
    margin-bottom: 32px;
  }
  & > p {
    margin-top: 32px;
    margin-right: 40px;
    font-size: 0.5em;
    line-height: 1.6;
  }
`;
