import React from "react";
import { HomeHeaderWrapper } from "./styled";

const HomeHeader = () => {

  return (
    <HomeHeaderWrapper>
      <h1>
        <span>Successful Strategy</span>
        <br />
        For your business
      </h1>
    </HomeHeaderWrapper>
  )
}

export default HomeHeader;